import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { format } from 'date-fns';
import { subscriptionApi } from '../utils/apiUtils';
const SubscriptionManagement = () => {
	const { user } = useAuth();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);


	const handlePortalSession = async () => {
		try {
			setLoading(true);

			const { url } = await subscriptionApi.createPortalSession();

			// Redirect to Stripe Customer Portal
			window.location.href = url;
		} catch (err) {
			setError('Failed to access subscription management. Please try again.');
			console.error('Portal session error:', err);
		} finally {
			setLoading(false);
		}
	};

	const renderSubscriptionDetails = () => {
		if (!user) return null;

		return (
			<div className="space-y-4">
				<div className="bg-white p-6 rounded-lg shadow-md">
					<h3 className="text-lg font-semibold mb-4">Subscription Details</h3>
					<div className="space-y-2">
						<p className="flex justify-between">
							<span className="text-gray-600">Status:</span>
							<span className={`font-medium ${user.stripeSubscriptionStatus === 'active' ? 'text-green-600' : 'text-yellow-600'
								}`}>
								{user.stripeSubscriptionStatus ?
									user.stripeSubscriptionStatus
										.split('_')
										.map(word => word.charAt(0).toUpperCase() + word.slice(1))
										.join(' ')
									: 'None'}
							</span>
						</p>
						<p className="flex justify-between">
							<span className="text-gray-600">Plan:</span>
							<span className="font-medium">
								{user.stripeSubscriptionTier ?
									user.stripeSubscriptionTier
										.split('_')
										.map(word => word.charAt(0).toUpperCase() + word.slice(1))
										.join(' ')
									: 'None'}
							</span>
						</p>
						{user.stripeTrialEndsDate && (
							<p className="flex justify-between">
								<span className="text-gray-600">Trial Ends:</span>
								<span className="font-medium">
									{format(new Date(user.stripeTrialEndsDate), 'MMM dd, yyyy')}
								</span>
							</p>
						)}
						{user.stripeCurrentPeriodEndDate && (
							<p className="flex justify-between">
								<span className="text-gray-600">Next Billing Date:</span>
								<span className="font-medium">
									{format(new Date(user.stripeCurrentPeriodEndDate), 'MMM dd, yyyy')}
								</span>
							</p>
						)}
						{user.stripeCancelAtPeriodEnd && (
							<p className="flex justify-between">
								<span className="text-gray-600">Cancellation Status:</span>
								<span className="font-medium text-red-500">
									Cancels at period end
								</span>
							</p>
						)}
						{user.stripeLatestInvoiceUrl && (
							<p className="flex justify-between">
								<span className="text-gray-600">Latest Invoice:</span>
								<a
									href={user.stripeLatestInvoiceUrl}
									target="_blank"
									rel="noopener noreferrer"
									className="text-blue-500 hover:text-blue-600 font-medium"
								>
									View Invoice
								</a>
							</p>
						)}
					</div>
				</div>

				<button
					onClick={handlePortalSession}
					disabled={loading}
					className={`w-full py-3 px-4 rounded-md font-medium transition-all duration-200 
            ${loading
							? 'bg-gray-300 cursor-not-allowed'
							: 'bg-blue-500 hover:bg-blue-600 text-white'}`}
				>
					{loading ? 'Loading...' : 'Manage Subscription'}
				</button>

				{error && (
					<div className="text-red-500 text-sm mt-2">
						{error}
					</div>
				)}
			</div>
		);
	};

	return (
		<div className="max-w-2xl mx-auto mt-8">
			<h2 className="text-2xl font-bold mb-6">Subscription Management</h2>
			{renderSubscriptionDetails()}
		</div>
	);
};

export default SubscriptionManagement; 
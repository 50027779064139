import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { FaUserCircle, FaBookmark, FaTags, FaCrown, FaSignOutAlt } from 'react-icons/fa';
import PaperCollection from './PaperCollection';
import SubscriptionManagement from './SubscriptionManagement';
import { paperApi } from '../utils/apiUtils';

function User() {
  const { user, signOut, loading, error, fetchUser, favoritePaperIds } = useAuth();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('profile');
  const [favoritePapers, setFavoritePapers] = useState([]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (!loading && !user && !error) {
      navigate('/sign-in');
    }
  }, [user, loading, error, navigate]);

  useEffect(() => {
    if (favoritePaperIds.length > 0) {
      fetchFavoritePapers(favoritePaperIds);
    }
  }, [favoritePaperIds]);

  const handleSignOut = async () => {
    console.log('Signing out...');
    try {
      await signOut();
      console.log('Sign out successful, navigating...');
      navigate('/sign-in');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const fetchFavoritePapers = async () => {
    try {
      const papers = [];
      for (const paperId of favoritePaperIds) {
        const { paper } = await paperApi.fetchPaperById(paperId);
        papers.push(paper);
      }
      setFavoritePapers(papers);
    } catch (error) {
      console.error('Error fetching favorite papers:', error);
    }
  };

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-md p-6 mt-8"
      >
        <p className="text-gray-600">Loading...</p>
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-md p-6 mt-8"
      >
        <p className="text-red-600">Error: {error}</p>
      </motion.div>
    );
  }

  if (!user) {
    return null;
  }

  const renderSubscriptionStatus = (status) => {
    const subscriptionClasses = {
      free_plan: "bg-gray-100 text-gray-700 border border-gray-300",
      pro_plan: "bg-gradient-to-r from-blue-500 to-blue-600 text-white",
      premium_plan: "bg-gradient-to-r from-purple-500 to-purple-600 text-white",
    };

    const subscriptionIcons = {
      free_plan: null,
      pro_plan: <FaCrown className="inline-block mr-1" />,
      premium_plan: <FaCrown className="inline-block mr-1" />,
    };

    const subscriptionLabel = status ? status.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : 'None';
    const bgColorClass = subscriptionClasses[status] || subscriptionClasses.free_plan;
    const icon = subscriptionIcons[status] || subscriptionIcons.free_plan;

    return (
      <span className={`inline-flex items-center px-4 py-1.5 rounded-full text-sm font-semibold ${bgColorClass} shadow-sm`}>
        {icon}
        {subscriptionLabel}
      </span>
    );
  };

  const tabs = [
    { id: 'profile', label: 'Profile', icon: FaUserCircle },
    { id: 'favorites', label: 'Favorite Papers', icon: FaBookmark },
    { id: 'tags', label: 'Followed Tags', icon: FaTags },
    { id: 'subscription', label: 'Subscription', icon: FaCrown }
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return (
          <div className="space-y-6 bg-white p-6 rounded-lg shadow-sm">
            {user.stripeSubscriptionStatus === null && (
              <div className="mb-6 p-4 bg-yellow-50 border border-yellow-200 rounded-lg">
                <p className="text-sm text-yellow-700">
                  Note: On your first login, it might take a few seconds to activate the free plan.
                  In the case of a cancelled subscription, please log out and log in again to activate the free plan.
                </p>
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm text-gray-500 mb-1">Name</p>
                  <p className="text-lg font-medium text-gray-900">{user.name}</p>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm text-gray-500 mb-1">Email</p>
                  <p className="text-lg font-medium text-gray-900">{user.email}</p>
                </div>
              </div>
              <div className="space-y-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm text-gray-500 mb-1">Subscription</p>
                  <div className="mt-1">{renderSubscriptionStatus(user.stripeSubscriptionTier)}</div>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm text-gray-500 mb-1">Last Login</p>
                  <p className="text-lg font-medium text-gray-900">
                    {new Date(user.lastLogin).toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      case 'favorites':
        return (
          <div>
            <PaperCollection papers={favoritePapers} onSave={() => { }} onRemove={() => { }} />
          </div>
        );
      case 'tags':
        return (
          <div>
            <p>Your followed tags will be displayed here.</p>
            {/* Add logic to fetch and display followed tags */}
          </div>
        );
      case 'subscription':
        return (
          <div>
            <SubscriptionManagement />
          </div>
        );
      default:
        return null;
    }
  };



  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
        <div className="flex items-center space-x-6">
          {/* <div className="flex-shrink-0">
            {user.picture && !imageError ? (
              <img
                src={user.picture}
                alt="Profile"
                className="w-24 h-24 rounded-full object-cover ring-4 ring-gray-100"
                onError={() => setImageError(true)}
              />
            ) : (
              <FaUserCircle className="w-24 h-24 text-gray-300" />
            )}
          </div> */}
          <div>
            <h1 className="text-3xl font-bold text-gray-900">{user.name}</h1>
            <p className="text-gray-500 mt-1">{user.email}</p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm">
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`
                  flex items-center px-6 py-4 text-sm font-medium transition-colors duration-200
                  ${activeTab === tab.id
                    ? 'border-b-2 border-blue-500 text-blue-600'
                    : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }
                `}
                onClick={() => setActiveTab(tab.id)}
              >
                <tab.icon className={`mr-2 ${activeTab === tab.id ? 'text-blue-500' : 'text-gray-400'}`} />
                {tab.label}
              </button>
            ))}
          </nav>
        </div>

        <div className="p-6">
          {renderTabContent()}
        </div>
      </div>

      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="mt-8 w-full sm:w-auto flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200"
        onClick={handleSignOut}
      >
        <FaSignOutAlt className="mr-2" />
        Sign Out
      </motion.button>
    </div>
  );
}

export default User;

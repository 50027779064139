import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { renderButton } from '../utils/uiUtils';
import { BookmarkIcon, TrashIcon, ArrowLeftIcon, LightBulbIcon } from '@heroicons/react/outline';
import { paperApi, aiApi } from '../utils/apiUtils';
function PaperInformation() {
	const [paper, setPaper] = useState(null);
	const [aiSummary, setAiSummary] = useState('');
	const [isLoadingSummary, setIsLoadingSummary] = useState(false);
	const [isFavorite, setIsFavorite] = useState(false);
	const { id } = useParams();
	const { user, addFavoritePaper, removeFavoritePaper, addAiSummarizedPaper, eligibility } = useAuth();
	const location = useLocation();
	const { from } = location.state || { from: { pathname: "/today" } };

	const isLoggedIn = user !== null;

	useEffect(() => {
		const fetchPaper = async () => {
			try {
				const { paper } = await paperApi.fetchPaperById(id);
				setPaper(paper);
			} catch (error) {
				console.error('Error fetching paper:', error);
			}
		};
		fetchPaper();
	}, [id]);

	useEffect(() => {
		setIsFavorite(user?.favoritePapers?.includes(id));
	}, [user, id]);

	const handleAdd = () => {
		if (!isLoggedIn || !eligibility.favoritePaperAllowed) return;
		addFavoritePaper(id);
		setIsFavorite(true);
	};

	const handleRemove = () => {
		if (!isLoggedIn) return;
		removeFavoritePaper(id);
		setIsFavorite(false);
	};

	const handleAISummary = async () => {
		if (!isLoggedIn || !eligibility.aiSummaryAllowed) return;

		setIsLoadingSummary(true);
		try {
			const { summary } = await aiApi.generateSummary(paper.id);
			setAiSummary(summary);
			await addAiSummarizedPaper(paper.id);
		} catch (error) {
			console.error('Error fetching AI summary:', error);
		} finally {
			setIsLoadingSummary(false);
		}
	};

	if (!paper) {
		return <div className="container mx-auto px-4 py-8 text-center">Loading...</div>;
	}

	return (
		<div className="container mx-auto px-4 py-8">
			<div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl mx-auto">
				<h2 className="text-3xl font-bold mb-6 text-gray-800">{paper.title}</h2>
				<div className="grid grid-cols-2 gap-4 mb-6">
					<div>
						<p className="text-sm text-gray-600"><span className="font-semibold">First Author:</span> {paper.first_author ? paper.first_author.split(',')[0] : 'Unknown Author'}</p>
						<p className="text-sm text-gray-600"><span className="font-semibold">Published:</span> {new Date(paper.publish_time).toLocaleDateString()}</p>
					</div>
					<div>
						<p className="text-sm text-gray-600"><span className="font-semibold">Topic:</span> {paper.topic}</p>
						<p className="text-sm text-gray-600"><span className="font-semibold">Subtopic:</span> {paper.subtopic}</p>
						<p className="text-sm text-gray-600"><span className="font-semibold">Tag:</span> {paper.tag}</p>
					</div>
				</div>
				<div className="mb-6">
					<h3 className="text-2xl font-semibold mb-2 text-gray-700">Abstract</h3>
					<p className="text-gray-600">{paper.abstract}</p>
				</div>
				<div className="mb-6">
					<h3 className="text-2xl font-semibold mb-2 text-gray-700">AI Summary</h3>
					{aiSummary ? (
						<p className="text-gray-600">{aiSummary}</p>
					) : (
						<p className="text-gray-500 italic">No AI summary available. Click the button below to generate one.</p>
					)}
				</div>
				<div className="flex flex-wrap gap-4">
					<Link
						to={from}
						className="inline-flex items-center bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition duration-300 ease-in-out"
					>
						<ArrowLeftIcon className="w-5 h-5 mr-2" />
						Back to {from.pathname === "/today" ? "Today's Papers" : "Papers"}
					</Link>
					{renderButton(
						handleAISummary,
						<LightBulbIcon className="w-5 h-5" />,
						isLoadingSummary ? 'Generating...' : 'Generate AI Summary',
						'bg-blue-600 hover:bg-blue-700',
						!isLoggedIn ? 'Login required' : eligibility.aiSummaryNotAllowedReason
					)}
					{isFavorite
						? renderButton(
							handleRemove,
							<TrashIcon className="w-5 h-5" />,
							'Remove from Favorites',
							'bg-red-600 hover:bg-red-700',
							!isLoggedIn ? 'Login required' : ''
						)
						: renderButton(
							handleAdd,
							<BookmarkIcon className="w-5 h-5" />,
							'Save to Favorites',
							'bg-green-600 hover:bg-green-700',
							!isLoggedIn ? 'Login required' : eligibility.favoritePaperNotAllowedReason
						)}
				</div>
			</div>
		</div>
	);
}

export default PaperInformation;

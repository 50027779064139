import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TOPICS_DATA, TOPICS_ICONS } from '../constants/tagConstants';

function TagFilter({ onTagSelect }) {
  const [selectedTags, setSelectedTags] = useState([]);
  const [activeTopic, setActiveTopic] = useState(null);
  const [activeSubtopic, setActiveSubtopic] = useState(null);

  const handleTopicClick = (topic) => {
    if (activeTopic === topic) {
      setActiveTopic(null);
      setActiveSubtopic(null);
    } else {
      setActiveTopic(topic);
      setActiveSubtopic(null);
    }
  };

  const handleSubtopicClick = (topic, subtopic) => {
    if (activeSubtopic === subtopic) {
      setActiveSubtopic(null);
    } else {
      setActiveSubtopic(subtopic);
    }
  };

  const handleTagSelect = (path) => {
    const newTags = selectedTags.includes(path)
      ? selectedTags.filter(t => t !== path)
      : [...selectedTags, path];

    setSelectedTags(newTags);
    onTagSelect(newTags);
  };

  const handleRemoveTag = (tagToRemove) => {
    const newTags = selectedTags.filter(tag => tag !== tagToRemove);
    setSelectedTags(newTags);
    onTagSelect(newTags);
  };

  const handleRemoveAllTags = () => {
    setSelectedTags([]);
    onTagSelect([]);
    setActiveTopic(null);
    setActiveSubtopic(null);
  };

  const handleBack = () => {
    if (activeSubtopic) {
      setActiveSubtopic(null);
    } else if (activeTopic) {
      setActiveTopic(null);
    }
  };

  const getSelectedCount = (topic, subtopic = null) => {
    return selectedTags.filter(tag => {
      const [tagTopic, tagSubtopic] = tag.split('/');
      return subtopic 
        ? tagTopic === topic && tagSubtopic === subtopic
        : tagTopic === topic;
    }).length;
  };

  const SelectButton = ({ path, isSelected }) => (
    <motion.button
      onClick={(e) => {
        e.stopPropagation();
        handleTagSelect(path);
      }}
      className={`ml-2 px-2 py-0.5 rounded-full text-xs transition-colors ${
        isSelected 
          ? 'bg-blue-600 text-white hover:bg-blue-700' 
          : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
      }`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {isSelected ? 'Selected' : 'Select'}
    </motion.button>
  );

  return (
    <div className="space-y-4">
      {/* Selected Tags Display */}
      {selectedTags.length > 0 && (
        <motion.div 
          className="flex flex-wrap gap-2 p-3 bg-gray-50 rounded-lg"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
        >
          {selectedTags.map((tag) => (
            <motion.span
              key={tag}
              className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              whileHover={{ scale: 1.05 }}
            >
              {tag.split('/').pop()}
              <span className="mx-1 text-gray-400">in</span>
              {tag.split('/').slice(0, 2).join('/')}
              <button
                onClick={() => handleRemoveTag(tag)}
                className="ml-2 text-blue-600 hover:text-blue-800"
              >
                ×
              </button>
            </motion.span>
          ))}
          <motion.button
            onClick={handleRemoveAllTags}
            className="px-3 py-1 rounded-full text-sm bg-red-100 text-red-800 hover:bg-red-200"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Clear All
          </motion.button>
        </motion.div>
      )}

      {/* Navigation Header */}
      {(activeTopic || activeSubtopic) && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex items-center space-x-2 text-sm text-gray-600 mb-2"
        >
          <button
            onClick={handleBack}
            className="hover:text-blue-600 flex items-center"
          >
            ← Back
          </button>
          <span className="text-gray-400">|</span>
          <span>{activeTopic}</span>
          {activeSubtopic && (
            <>
              <span className="text-gray-400">/</span>
              <span>{activeSubtopic}</span>
            </>
          )}
        </motion.div>
      )}

      {/* Dynamic Content Area */}
      <AnimatePresence mode="wait">
        {!activeTopic ? (
          // Topics Grid
          <motion.div
            key="topics"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
          >
            {Object.entries(TOPICS_DATA.topics).map(([topic]) => {
              const IconComponent = TOPICS_ICONS[topic];
              const selectedCount = getSelectedCount(topic);
              const isSelected = selectedTags.includes(topic);
              
              return (
                <motion.div
                  key={topic}
                  className={`p-4 rounded-lg border ${
                    selectedCount > 0 ? 'border-blue-200 bg-blue-50' : 'border-gray-200 bg-white'
                  } hover:border-blue-300 transition-colors`}
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => handleTopicClick(topic)}
                      className="flex items-center text-lg font-medium flex-grow"
                    >
                      {IconComponent && <IconComponent className="w-5 h-5 mr-2" />}
                      <span>{topic}</span>
                      {selectedCount > 0 && (
                        <span className="ml-2 px-2 py-0.5 text-sm bg-blue-200 text-blue-800 rounded-full">
                          {selectedCount}
                        </span>
                      )}
                    </button>
                    <div className="flex items-center">
                      <SelectButton path={topic} isSelected={isSelected} />
                      <motion.span className="ml-2 text-gray-400">→</motion.span>
                    </div>
                  </div>
                </motion.div>
              );
            })}
          </motion.div>
        ) : !activeSubtopic ? (
          // Subtopics List
          <motion.div
            key="subtopics"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            className="space-y-2"
          >
            {Object.entries(TOPICS_DATA.topics[activeTopic]).map(([subtopic]) => {
              const selectedCount = getSelectedCount(activeTopic, subtopic);
              const isSelected = selectedTags.includes(`${activeTopic}/${subtopic}`);

              return (
                <motion.div
                  key={subtopic}
                  className={`p-3 rounded-lg border ${
                    selectedCount > 0 ? 'border-blue-200 bg-blue-50' : 'border-gray-200 bg-white'
                  } hover:border-blue-300 transition-colors`}
                  whileHover={{ scale: 1.01 }}
                >
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => handleSubtopicClick(activeTopic, subtopic)}
                      className="flex-grow text-left"
                    >
                      <span>{subtopic}</span>
                      {selectedCount > 0 && (
                        <span className="ml-2 px-2 py-0.5 text-sm bg-blue-200 text-blue-800 rounded-full">
                          {selectedCount}
                        </span>
                      )}
                    </button>
                    <SelectButton 
                      path={`${activeTopic}/${subtopic}`} 
                      isSelected={isSelected}
                    />
                  </div>
                </motion.div>
              );
            })}
          </motion.div>
        ) : (
          // Tags List
          <motion.div
            key="tags"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            className="space-y-2"
          >
            {Object.keys(TOPICS_DATA.topics[activeTopic][activeSubtopic]).map(tag => {
              const fullPath = `${activeTopic}/${activeSubtopic}/${tag}`;
              const isSelected = selectedTags.includes(fullPath);
              
              return (
                <motion.div
                  key={tag}
                  className={`p-3 rounded-lg border ${
                    isSelected ? 'border-blue-200 bg-blue-50' : 'border-gray-200 bg-white'
                  } hover:border-blue-300 transition-colors`}
                  whileHover={{ scale: 1.01 }}
                >
                  <div className="flex items-center justify-between">
                    <span>{tag}</span>
                    <SelectButton path={fullPath} isSelected={isSelected} />
                  </div>
                </motion.div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default TagFilter;
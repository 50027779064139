// AuthContext.js

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { authApi, subscriptionApi } from '../utils/apiUtils';
import { getEligibility } from '../utils/eligibilityUtils';
// Create AuthContext with default value
const AuthContext = createContext(null);

/**
 * AuthProvider component that provides authentication state and actions to its children.
 */
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // To handle loading state
  const [error, setError] = useState(null);

  // Add eligibility state directly in AuthContext
  const [eligibility, setEligibility] = useState({
    favoritePaperQuota: 0,
    favoriteTagQuota: 0,
    aiSummaryQuota: 0,
    favoritePaperUsage: 0,
    favoriteTagUsage: 0,
    aiSummaryUsage: 0,
    isSubscriptionActive: false,
    hasCancelled: false,
    subscriptionEndDate: null,
    isWithinSubscriptionPeriod: false,
    favoritePaperAllowed: false,
    favoriteTagAllowed: false,
    aiSummaryAllowed: false,
    favoritePaperNotAllowedReason: '',
    favoriteTagNotAllowedReason: '',
    aiSummaryNotAllowedReason: ''
  });

  // Fetch authenticated user data from the server on component mount
  const fetchUser = useCallback(async () => {
    try {
      setLoading(true);
      const { user } = await authApi.getUser();
      setUser(user);
      await subscriptionApi.syncWithStripe();
      setError(null);
    } catch (err) {
      setUser(null);
      setError(err.response?.data?.message || 'Failed to fetch user');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);


  // Update signIn to fetch redirect URL and navigate
  const signIn = useCallback(async (method) => {
    try {
      setLoading(true);
      const { redirectUrl } = await authApi.signIn(method);
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Login failed');
      setLoading(false);
    }
  }, []);

  // signOut function
  const signOut = useCallback(async () => {
    try {
      setLoading(true);
      await authApi.logout();
      setUser(null);
      setError(null);
    } catch (err) {
      setError(err.response?.data?.message || 'Logout failed');
    } finally {
      setLoading(false);
    }
  }, []);


  const addFavoritePaper = useCallback(async (paperId) => {
    try {

      const { user } = await authApi.addFavoritePaper(paperId);
      setUser(user);
    } catch (err) {
      setError(err.message || 'Failed to add favorite paper');
      throw err;
    }
  }, [eligibility]);

  const removeFavoritePaper = useCallback(async (paperId) => {
    try {
      const { user } = await authApi.removeFavoritePaper(paperId);
      setUser(user);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to remove favorite paper');
    }
  }, []);

  const favoritePaperIds = useMemo(() => user?.favoritePapers || [], [user]);

  const addFavoriteTag = useCallback(async (tag) => {
    try {
      const { user } = await authApi.addFavoriteTag(tag);
      setUser(user);
    } catch (err) {
      setError(err.message || 'Failed to add favorite tag');
      throw err;
    }
  }, [eligibility]);

  const removeFavoriteTag = useCallback(async (tag) => {
    try {
      const { user } = await authApi.removeFavoriteTag(tag);
      setUser(user);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to remove favorite tag');
    }
  }, []);

  const favoriteTags = useMemo(() => user?.favoriteTags || [], [user]);

  // increment and decrement ai summary used
  const addAiSummarizedPaper = useCallback(async (paperId) => {
    try {

      const { user } = await authApi.addAiSummarizedPaper(paperId);
      setUser(user);
    } catch (err) {
      setError(err.message || 'Failed to add AI summarized paper');
      throw err;
    }
  }, [eligibility]);



  const aiSummarizedPaperIds = useMemo(() => user?.aiSummarizedPapers || [], [user]);

  // Add useEffect for eligibility
  useEffect(() => {
    const fetchEligibility = async () => {
      if (!user) {
        return;
      }


      try {
        const eligibility = await getEligibility(user);
        setEligibility(eligibility);
      } catch (error) {
        console.error('Error fetching active eligibility:', error);
      }

    };

    fetchEligibility();
  }, [user]);


  // Memoized context value
  const value = useMemo(
    () => ({
      user,
      loading,
      error,
      signIn,
      signOut,
      fetchUser,
      addFavoritePaper,
      removeFavoritePaper,
      favoritePaperIds,
      addFavoriteTag,
      removeFavoriteTag,
      favoriteTags,
      addAiSummarizedPaper,
      aiSummarizedPaperIds,
      eligibility,
    }),
    [
      user, loading, error, signIn, signOut, fetchUser,
      addFavoritePaper, removeFavoritePaper,
      favoritePaperIds, favoriteTags,
      addAiSummarizedPaper, aiSummarizedPaperIds,
      eligibility
    ]
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Custom hook to access the AuthContext.
 * Throws an error if used outside of AuthProvider.
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import SearchBar from './SearchBar';
import TagFilter from './TagFilter';
import { paperApi } from '../utils/apiUtils';
import PaperCollection from './PaperCollection';
import { filterPapers } from '../utils/paperUtils';
function Today() {
  const [papers, setPapers] = useState([]);
  const [filteredPapers, setFilteredPapers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState(null);

  useEffect(() => {
    const getPapers = async () => {
      try {
        const { papers } = await paperApi.fetchPapersToday();
        setPapers(papers);
        setFilteredPapers(papers);
      } catch (error) {
        console.error('Error fetching papers:', error);
      }
    };

    getPapers();
  }, []);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setFilteredPapers(filterPapers(papers, term, selectedTag));
  };

  const handleTagSelect = useCallback((tag) => {
    setSelectedTag(tag);
    setFilteredPapers(filterPapers(papers, searchTerm, tag));
  }, [papers, searchTerm]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4"
    >
      <div className="mb-4">
        <SearchBar onSearch={handleSearch} />
      </div>
      <div className="mb-4">
        <TagFilter onTagSelect={handleTagSelect} />
      </div>
      <PaperCollection
        papers={filteredPapers}
      />
    </motion.div>
  );
}

export default Today;

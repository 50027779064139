import React, { useState } from 'react';
import { motion } from 'framer-motion';

function SearchBar({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchTerm);
  };

  return (
    <form onSubmit={handleSubmit} className="mb-6 sm:mb-8">
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search papers..."
          className="w-full py-2 sm:py-3 px-3 sm:px-4 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-full text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-400 text-sm sm:text-base"
        />
        <motion.button
          type="submit"
          className="absolute right-1 sm:right-2 top-1 sm:top-2 bg-yellow-400 text-purple-800 rounded-full px-3 sm:px-4 py-1 text-sm sm:text-base"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Search
        </motion.button>
      </div>
    </form>
  );
}

export default SearchBar;
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import SearchBar from './SearchBar';
import TagFilter from './TagFilter';
import PaperCollection from './PaperCollection';
import { paperApi } from '../utils/apiUtils';
import { filterPapers } from '../utils/paperUtils';
import { pageTransition, renderLoadingState, renderErrorState } from '../utils/uiUtils';

function Archive() {
  const [papers, setPapers] = useState([]);
  const [filteredPapers, setFilteredPapers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState(null);
  const [startDate, setStartDate] = useState('2024-08-19');
  const [endDate, setEndDate] = useState('2024-08-20');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadPapers = async () => {
      try {
        const { papers } = await paperApi.fetchPapersByDateRange(startDate, endDate);
        setPapers(papers);
        setFilteredPapers(papers);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    loadPapers();
  }, [startDate, endDate]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setFilteredPapers(filterPapers(papers, term, selectedTag));
  };

  const handleTagSelect = (tag) => {
    setSelectedTag(tag);
    setFilteredPapers(filterPapers(papers, searchTerm, tag));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  if (loading) return renderLoadingState();
  if (error) return renderErrorState(error);

  return (
    <motion.div
      {...pageTransition}
      className="container mx-auto px-4"
    >
      <div className="mb-4">
        <SearchBar onSearch={handleSearch} />
      </div>
      <div className="mb-4">
        <TagFilter onTagSelect={handleTagSelect} />
      </div>
      <div className="mb-4 flex space-x-4">
        <input
          type="date"
          name="startDate"
          value={startDate}
          onChange={handleDateChange}
          className="p-2 rounded border border-gray-300"
        />
        <input
          type="date"
          name="endDate"
          value={endDate}
          onChange={handleDateChange}
          className="p-2 rounded border border-gray-300"
        />
      </div>
      <PaperCollection
        papers={filteredPapers}
      />
    </motion.div>
  );
}

export default Archive;

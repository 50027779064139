import React from 'react';

function TermsOfService() {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      <p className="mb-4 text-gray-600">
        Last updated: March 2024
      </p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Subscription Terms</h2>
        <div className="space-y-4">
          <p>
            Paper Digest offers different subscription tiers with varying features and usage limits. By subscribing to our service:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>You agree to pay the subscription fees for your selected plan</li>
            <li>Your subscription will automatically renew unless cancelled</li>
            <li>Usage limits apply based on your subscription tier</li>
            <li>Refunds are handled according to our refund policy</li>
          </ul>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. User Account</h2>
        <div className="space-y-4">
          <p>When creating an account, you agree to:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Provide accurate and complete information</li>
            <li>Maintain the security of your account credentials</li>
            <li>Not share your account access with others</li>
            <li>Notify us immediately of any unauthorized access</li>
          </ul>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. AI Summary Service</h2>
        <p className="mb-4">
          Our AI summary service is provided "as is." While we strive for accuracy, we cannot guarantee that AI-generated summaries will be error-free or meet your specific requirements.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. Usage Restrictions</h2>
        <div className="space-y-4">
          <p>You agree not to:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Exceed your subscription's usage limits</li>
            <li>Use our service for any unlawful purpose</li>
            <li>Attempt to circumvent any access restrictions</li>
            <li>Redistribute or resell our AI summaries</li>
          </ul>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Termination</h2>
        <p className="mb-4">
          We reserve the right to suspend or terminate your account for violations of these terms or for any other reason at our discretion.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">6. Changes to Terms</h2>
        <p>
          We may modify these terms at any time. Continued use of our service after changes constitutes acceptance of the modified terms.
        </p>
      </section>
    </div>
  );
}

export default TermsOfService;
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Paper API endpoints
export const paperApi = {
  fetchPapers: async (endpoint) => {
    try {
      const response = await api.get(`/papers/${endpoint}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching papers from ${endpoint}:`, error);
      throw error;
    }
  },

  fetchPapersByDateRange: async (startDate, endDate) => {
    try {
      const response = await api.get(`/papers/archive/${startDate}/${endDate}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching papers by date range:', error);
      throw error;
    }
  },

  fetchPapersToday: async () => {
    try {
      const response = await api.get('/papers/today');
      return response.data;
    } catch (error) {
      console.error('Error fetching papers today:', error);
      throw error;
    }
  },

  fetchPaperById: async (paperId) => {
    try {
      const response = await api.get(`/papers/fetch/${paperId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching paper:', error);
      throw error;
    }
  },

  fetchPapersByIds: async (paperIds) => {
    try {
      const papers = [];
      for (const paperId of paperIds) {
        const paper = await paperApi.fetchPaperById(paperId);
        papers.push(paper);
      }
      return papers;
    } catch (error) {
      console.error('Error fetching papers:', error);
      throw error;
    }
  }
};

// Auth API endpoints
export const authApi = {
  getUser: async () => {
    try {
      const response = await api.get('/auth/user');
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching user:', error);
      throw error;
    }
  },

  signIn: async (method) => {
    try {
      const response = await api.post(`/auth/${method}`);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Error signing in';
      console.error('Error signing in:', errorMessage);
      throw new Error(errorMessage);
    }
  },

  logout: async () => {
    try {
      await api.post('/auth/logout');
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  },

  addFavoritePaper: async (paperId) => {
    try {
      const response = await api.post('/auth/add-favorite-paper', { paperId });
      return response.data;
    } catch (error) {
      console.error('Error adding favorite paper:', error);
      throw error;
    }
  },

  removeFavoritePaper: async (paperId) => {
    try {
      const response = await api.post('/auth/remove-favorite-paper', { paperId });
      return response.data;
    } catch (error) {
      console.error('Error removing favorite paper:', error);
      throw error;
    }
  },

  addFavoriteTag: async (tag) => {
    try {
      const response = await api.post('/auth/add-favorite-tag', { tag });
      return response.data;
    } catch (error) {
      console.error('Error adding favorite tag:', error);
      throw error;
    }
  },

  removeFavoriteTag: async (tag) => {
    try {
      const response = await api.post('/auth/remove-favorite-tag', { tag });
      return response.data;
    } catch (error) {
      console.error('Error removing favorite tag:', error);
      throw error;
    }
  },

  addAiSummarizedPaper: async (paperId) => {
    try {
      const response = await api.post('/auth/add-ai-summarized-paper', { paperId });
      return response.data;
    } catch (error) {
      console.error('Error adding AI summarized paper:', error);
      throw error;
    }
  },

};


export const emailAuthApi = {
  requestEmailAuth: async (email) => {
    try {
      const response = await api.post('/auth/email-auth/request', { email });
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to send authentication code';
      console.error('Email auth request error:', errorMessage);
      throw new Error(errorMessage);
    }
  },

  verifyEmailAuth: async (email, code) => {
    try {
      const response = await api.post('/auth/email-auth/verify', { email, code });
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to verify code';
      console.error('Email auth verification error:', errorMessage);
      throw new Error(errorMessage);
    }
  }
};

// AI API endpoints
export const aiApi = {
  generateSummary: async (paperId) => {
    try {
      const response = await api.post('/ai/summary', { paperId });
      return response.data;
    } catch (error) {
      console.error('Error generating AI summary:', error);
      throw error;
    }
  }
};

// Subscription API endpoints
export const subscriptionApi = {

  getProducts: async () => {
    try {
      const response = await api.get('/subscription/products');
      return response.data;
    } catch (error) {
      console.error('Error fetching products:', error);
      throw error;
    }
  },

  createCheckoutSession: async (product) => {
    try {
      const response = await api.post('/subscription/create-checkout-session', { product });
      return response.data;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  },

  createPortalSession: async () => {
    try {
      const response = await api.post('/subscription/create-portal-session');
      return response.data;
    } catch (error) {
      console.error('Error creating portal session:', error);
      throw error;
    }
  },

  syncWithStripe: async () => {
    try {
      const response = await api.post('/subscription/sync-status');
      return response.data;
    } catch (error) {
      console.error('Error syncing subscription status:', error);
      throw error;
    }
  },

  checkoutSuccess: async (sessionId) => {
    try {
      const response = await api.get(`/subscription/checkout-success?session_id=${sessionId}`);
      return response.data;
    } catch (error) {
      console.error('Error handling payment:', error);
      throw error;
    }
  },

  getActiveEntitlements: async () => {
    try {
      const response = await api.get('/subscription/active-entitlements');
      return response.data;
    } catch (error) {
      console.error('Error fetching active entitlements:', error);
      throw error;
    }
  }

};

export const createPortalSession = async () => {
  try {
    const response = await fetch('/api/subscription/create-portal-session', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to create portal session');
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating portal session:', error);
    throw error;
  }
};

// Add this to the existing api object
export const statsApi = {
  getGeneralStats: async () => {
    try {
      const response = await api.get('/stats/general');
      return response.data;
    } catch (error) {
      console.error('Error fetching general statistics:', error);
      throw error;
    }
  },

  getHotPapers: async () => {
    try {
      const response = await api.get('/stats/hot-papers');
      return response.data;
    } catch (error) {
      console.error('Error fetching hot papers:', error);
      throw error;
    }
  }
};

import { motion } from 'framer-motion';
import Tooltip from '../components/Tooltip';

export const pageTransition = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	transition: { duration: 0.5 }
};

export const buttonVariants = {
	hover: { scale: 1.05 },
	tap: { scale: 0.95 }
};

export const renderButton = (onClick, icon, text, bgColor, disabledReason) => {
	const isDisabled = disabledReason && disabledReason !== '';
	const buttonClass = `inline-flex items-center px-3 py-2 text-sm font-medium text-white ${bgColor} rounded-md hover:${bgColor.replace('bg-', 'bg-').replace('600', '700')} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${bgColor.replace('bg-', '')} transition duration-150 ease-in-out ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`;

	return (
		<Tooltip content={isDisabled ? disabledReason : ''}>
			<button
				onClick={onClick}
				disabled={isDisabled}
				className={buttonClass}
			>
				{icon}
				<span className="ml-1">{text}</span>
			</button>
		</Tooltip>
	);
};

export const renderLoadingState = () => (
	<motion.div
		{...pageTransition}
		className="bg-white rounded-lg shadow-md p-6 mt-8"
	>
		<p className="text-gray-600">Loading...</p>
	</motion.div>
);

export const renderErrorState = (error) => (
	<motion.div
		{...pageTransition}
		className="bg-white rounded-lg shadow-md p-6 mt-8"
	>
		<p className="text-red-600">Error: {error}</p>
	</motion.div>
);

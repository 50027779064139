import topicsData from './topics.json'
import { FaBrain, FaCamera, FaRobot, FaAtom, FaGlobe, FaFlask, FaLaptopCode, FaDna, FaPlug, FaChartLine, FaRandom } from 'react-icons/fa';

// export const TAGS = {
//   'Machine Learning': ['Deep Learning', 'Reinforcement Learning', 'Natural Language Processing'],
//   'Computer Vision': ['Image Recognition', 'Object Detection', 'Segmentation'],
//   'Robotics': ['Autonomous Systems', 'Human-Robot Interaction', 'Motion Planning'],
//   'Quantum Computing': ['Quantum Algorithms', 'Quantum Error Correction', 'Quantum Hardware'],
//   'Astrophysics': ['Galactic Astrophysics', 'Extragalactic Astrophysics', 'Cosmology', 'Planetary Science', 'High Energy Astrophysics', 'Stellar Astrophysics'],
//   'Condensed Matter Physics': ['Quantum Materials', 'Soft Matter Physics', 'Nanoscale Physics', 'Strongly Correlated Systems'],
//   'Quantum Physics': ['Quantum Information', 'Quantum Optics', 'Quantum Simulation', 'Quantum Foundations'],
//   'Computer Science': ['Artificial Intelligence', 'Computer Vision', 'Natural Language Processing', 'Algorithms and Data Structures'],
//   'Quantitative Biology': ['Bioinformatics', 'Systems Biology', 'Computational Neuroscience', 'Evolutionary Biology'],
//   'Electrical Engineering and Systems Science': ['Signal Processing', 'Control Systems', 'Communications', 'Machine Learning for EE'],
//   'Economics and Finance': ['Microeconomics', 'Macroeconomics', 'Econometrics', 'Quantitative Finance'],
//   'Interdisciplinary Topics': ['Quantum Computing', 'Network Science', 'Climate Science', 'Biophysics', 'Quantum Biology'],
// };

export const TOPICS_DATA = topicsData;

console.log(TOPICS_DATA)
export const TOPICS_ICONS = {
  'Machine Learning': FaBrain,
  'Computer Vision': FaCamera,
  'Robotics': FaRobot,
  'Quantum Computing': FaAtom,
  'Astrophysics': FaGlobe,
  'Condensed Matter Physics': FaFlask,
  'Quantum Physics': FaAtom,
  'Computer Science': FaLaptopCode,
  'Quantitative Biology': FaDna,
  'Electrical Engineering and Systems Science': FaPlug,
  'Economics and Finance': FaChartLine,
  'Interdisciplinary Topics': FaRandom,
};
import React from 'react';
import { motion } from 'framer-motion';
import { useLocation, Link } from 'react-router-dom';
import { FaExclamationTriangle, FaHome } from 'react-icons/fa';

function ErrorPage() {
	const location = useLocation();

	// Parse URL query parameters
	const queryParams = new URLSearchParams(location.search);
	const queryMessage = queryParams.get('message');

	let error;
	if (!queryMessage) {
		error = location.state?.error || {
			status: queryParams.get('status') || 404,
			message: queryMessage || 'Page not found',
			details: queryParams.get('details') || 'The page you are looking for might have been removed or is temporarily unavailable.'
		};
	} else {
		error = {
			status: null,
			message: queryMessage,
			details: ''
		};
	}

	const getErrorTitle = (status) => {
		switch (status) {
			case 401:
				return 'Unauthorized';
			case 403:
				return 'Forbidden';
			case 404:
				return 'Page Not Found';
			case 500:
				return 'Server Error';
			default:
				return 'Something Went Wrong';
		}
	};

	return (
		<motion.div
			className="min-h-[80vh] flex items-center justify-center px-4"
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.5 }}
		>
			<div className="text-center">
				<motion.div
					className="flex justify-center mb-8"
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					transition={{ delay: 0.2 }}
				>
					<FaExclamationTriangle className="text-6xl text-yellow-500" />
				</motion.div>

				<h1 className="text-4xl font-bold text-white mb-4">
					{getErrorTitle(error.status)}
				</h1>

				<div className="bg-white/90 rounded-lg p-6 mb-8 max-w-md">
					<p className="text-gray-800 text-xl mb-4">
						{error.message}
					</p>
					<p className="text-gray-600">
						{error.details}
					</p>
				</div>

				<Link
					to="/"
					className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors duration-200"
				>
					<FaHome className="mr-2" />
					Return Home
				</Link>
			</div>
		</motion.div>
	);
}

export default ErrorPage;
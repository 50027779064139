import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { motion } from 'framer-motion';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { subscriptionApi } from '../utils/apiUtils';
const PaymentSuccess = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { fetchUser } = useAuth();
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const handleSuccess = async () => {
			try {
				const sessionId = searchParams.get('session_id');
				if (!sessionId) {
					throw new Error('No session ID found');
				}

				await subscriptionApi.checkoutSuccess(sessionId);

				await fetchUser();

				setTimeout(() => {
					navigate('/user');
				}, 3000);
			} catch (err) {
				console.error('Error processing payment success:', err);
				setError('There was an error processing your payment. Please contact support.');
			} finally {
				setLoading(false);
			}
		};

		handleSuccess();
	}, [searchParams, fetchUser, navigate]);

	if (loading) {
		return (
			<motion.div
				className="max-w-xl mx-auto mt-20 p-8 bg-white rounded-2xl shadow-lg text-center"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
			>
				<div className="flex flex-col items-center">
					<motion.div
						animate={{ rotate: 360 }}
						transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
						className="mb-6"
					>
						<div className="h-16 w-16 border-4 border-blue-500 border-t-transparent rounded-full" />
					</motion.div>
					<h1 className="text-2xl font-bold text-gray-800 mb-4">Processing your payment...</h1>
					<p className="text-gray-600">Please wait while we confirm your transaction.</p>
				</div>
			</motion.div>
		);
	}

	if (error) {
		return (
			<motion.div
				className="max-w-xl mx-auto mt-20 p-8 bg-white rounded-2xl shadow-lg text-center"
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
			>
				<ExclamationCircleIcon className="h-20 w-20 text-red-500 mx-auto mb-6" />
				<h1 className="text-2xl font-bold text-red-600 mb-4">Payment Error</h1>
				<p className="text-gray-600 mb-6">{error}</p>
				<motion.button
					whileHover={{ scale: 1.02 }}
					whileTap={{ scale: 0.98 }}
					onClick={() => window.location.href = '/support'}
					className="bg-blue-600 text-white py-3 px-8 rounded-xl hover:bg-blue-700 transition duration-300 shadow-md"
				>
					Contact Support
				</motion.button>
			</motion.div>
		);
	}

	return (
		<motion.div
			className="max-w-xl mx-auto mt-20 p-8 bg-white rounded-2xl shadow-lg text-center"
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.5 }}
		>
			<motion.div
				initial={{ scale: 0 }}
				animate={{ scale: 1 }}
				transition={{ delay: 0.2, type: "spring", stiffness: 100 }}
			>
				<CheckCircleIcon className="h-20 w-20 text-green-500 mx-auto mb-6" />
			</motion.div>

			<h1 className="text-3xl font-bold text-gray-800 mb-4">Payment Successful!</h1>
			<div className="space-y-4">
				<p className="text-gray-600">
					Thank you for your subscription. Your account has been upgraded successfully.
				</p>
				<div className="h-1 w-16 bg-gray-200 rounded-full mx-auto" />
				<p className="text-gray-500 text-sm">
					Redirecting you to your dashboard in a few seconds...
				</p>
			</div>
		</motion.div>
	);
};

export default PaymentSuccess;
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { XCircleIcon } from '@heroicons/react/outline';

const PaymentCancel = () => {
  return (
    <motion.div
      className="max-w-2xl mx-auto mt-20 p-8 bg-white rounded-2xl shadow-lg text-center"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.2, type: "spring", stiffness: 100 }}
      >
        <XCircleIcon className="h-20 w-20 text-red-500 mx-auto mb-6" />
      </motion.div>

      <h1 className="text-3xl font-bold mb-4 text-gray-800">Payment Cancelled</h1>
      <p className="text-gray-600 mb-8 leading-relaxed">
        Your payment was not processed. If you encountered any issues or have questions,
        our support team is here to help.
      </p>

      <div className="flex flex-col sm:flex-row gap-4 justify-center">
        <motion.div
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Link
            to="/pricing"
            className="inline-block bg-blue-600 text-white py-3 px-8 rounded-xl hover:bg-blue-700 transition duration-300 shadow-md"
          >
            Return to Pricing
          </Link>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <a
            href="mailto:support@yourcompany.com"
            className="inline-block bg-gray-100 text-gray-700 py-3 px-8 rounded-xl hover:bg-gray-200 transition duration-300"
          >
            Contact Support
          </a>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default PaymentCancel;
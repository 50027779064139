import { subscriptionApi } from './apiUtils';

export const getEligibility = async (user) => {
	try {

		// Check subscription status and cancellation
		const isSubscriptionActive = user.stripeSubscriptionStatus !== null && user.stripeSubscriptionTier !== null && user.stripeSubscriptionId !== null;
		console.log('isSubscriptionActive', isSubscriptionActive);
		let favoritePaperQuota = 0;
		let favoriteTagQuota = 0;
		let aiSummaryQuota = 0;

		if (isSubscriptionActive) {
			const entitlements = await subscriptionApi.getActiveEntitlements();
			const { activeEntitlements: { data } } = entitlements;

			// Find feature entitlements
			const findFeature = (key) => data.find(
				item => item.feature.lookup_key.includes(key) ? item.feature : null
			);

			const favoritePaperFeature = findFeature('save-paper');
			const favoriteTagFeature = findFeature('save-topic');
			const aiSummaryFeature = findFeature('ai-summary');

			// Get quotas from features
			favoritePaperQuota = favoritePaperFeature?.feature?.metadata?.quantity || Infinity;
			favoriteTagQuota = favoriteTagFeature?.feature?.metadata?.quantity || Infinity;
			aiSummaryQuota = aiSummaryFeature?.feature?.metadata?.quantity || Infinity;

		}
		// Check usage against quotas
		const favoritePaperUsage = user.favoritePapers?.length || 0;
		const favoriteTagUsage = user.favoriteTags?.length || 0;
		const aiSummaryUsage = user.aiSummarizedPapers?.length || 0;

		// Determine if features are allowed and why not
		const checkFeatureEligibility = (quota, usage, featureName) => {
			if (!isSubscriptionActive) {
				return {
					allowed: false,
					reason: `Feature requires an active subscription`
				};
			}

			if (quota === 0) {
				return {
					allowed: false,
					reason: `${featureName} not included in your plan`
				};
			}

			if (quota === Infinity) {
				return {
					allowed: true,
					reason: ''
				};
			}

			if (usage >= quota) {
				return {
					allowed: false,
					reason: `${featureName} quota (${quota}) reached`
				};
			}

			return {
				allowed: true,
				reason: ''
			};
		};

		const favoritePaperStatus = checkFeatureEligibility(
			favoritePaperQuota,
			favoritePaperUsage,
			'Favorite Papers'
		);

		const favoriteTagStatus = checkFeatureEligibility(
			favoriteTagQuota,
			favoriteTagUsage,
			'Favorite Tags'
		);

		const aiSummaryStatus = checkFeatureEligibility(
			aiSummaryQuota,
			aiSummaryUsage,
			'AI Summaries'
		);

		console.log('favoritePaperStatus', favoritePaperStatus);
		console.log('favoriteTagStatus', favoriteTagStatus);
		console.log('aiSummaryStatus', aiSummaryStatus);

		return {
			// Quotas
			favoritePaperQuota,
			favoriteTagQuota,
			aiSummaryQuota,

			// Usage
			favoritePaperUsage,
			favoriteTagUsage,
			aiSummaryUsage,

			// Subscription status
			isSubscriptionActive,

			// Feature eligibility
			favoritePaperAllowed: favoritePaperStatus.allowed,
			favoriteTagAllowed: favoriteTagStatus.allowed,
			aiSummaryAllowed: aiSummaryStatus.allowed,

			// Reason messages
			favoritePaperNotAllowedReason: favoritePaperStatus.reason,
			favoriteTagNotAllowedReason: favoriteTagStatus.reason,
			aiSummaryNotAllowedReason: aiSummaryStatus.reason
		};
	} catch (error) {
		console.error('Error fetching active eligibility:', error);
		// Return restrictive defaults on error
		return {
			favoritePaperQuota: 0,
			favoriteTagQuota: 0,
			aiSummaryQuota: 0,
			favoritePaperUsage: 0,
			favoriteTagUsage: 0,
			aiSummaryUsage: 0,
			isSubscriptionActive: false,
			favoritePaperAllowed: false,
			favoriteTagAllowed: false,
			aiSummaryAllowed: false,
			favoritePaperNotAllowedReason: 'Error checking eligibility',
			favoriteTagNotAllowedReason: 'Error checking eligibility',
			aiSummaryNotAllowedReason: 'Error checking eligibility'
		};
	}
};
// Scoring weights for different match types
const WEIGHTS = {
	TITLE_EXACT: 1.0,
	TITLE_PARTIAL: 0.7,
	TAG_EXACT: 0.8,
	TAG_PARTIAL: 0.5,
	ABSTRACT_MATCH: 0.3
};

// Helper function to calculate string similarity
const calculateSimilarity = (str1, str2) => {
	const s1 = str1.toLowerCase();
	const s2 = str2.toLowerCase();
	
	// Exact match
	if (s1 === s2) return 1;
	
	// Contains match
	if (s1.includes(s2) || s2.includes(s1)) return 0.8;
	
	// Word match - check if any words match exactly
	const words1 = new Set(s1.split(/\s+/));
	const words2 = new Set(s2.split(/\s+/));
	const commonWords = [...words1].filter(word => words2.has(word));
	
	if (commonWords.length > 0) {
		return 0.5 * (commonWords.length / Math.max(words1.size, words2.size));
	}
	
	return 0;
};

// Calculate paper relevance score based on search term
const calculatePaperScore = (paper, searchTerm) => {
	if (!searchTerm) return 1;
	
	const searchLower = searchTerm.toLowerCase();
	let score = 0;
	
	// Title matching
	const titleSimilarity = calculateSimilarity(paper.title, searchTerm);
	score += titleSimilarity * WEIGHTS.TITLE_EXACT;
	
	// Tag matching
	const paperTags = `${paper.topic}/${paper.subtopic}/${paper.tag}`;
	const tagSimilarity = calculateSimilarity(paperTags, searchTerm);
	score += tagSimilarity * WEIGHTS.TAG_EXACT;
	
	// Abstract matching (if available)
	if (paper.abstract) {
		const abstractSimilarity = calculateSimilarity(paper.abstract, searchTerm);
		score += abstractSimilarity * WEIGHTS.ABSTRACT_MATCH;
	}
	
	return score;
};

// Enhanced matchesTags function to handle hierarchical filtering
const matchesTags = (paper, selectedTags) => {
	if (!selectedTags || selectedTags.length === 0) return true;
	
	const paperPath = `${paper.topic}/${paper.subtopic}/${paper.tag}`.toLowerCase();
	
	// Check if paper matches ANY of the selected tags (OR logic between different topics)
	return selectedTags.some(tagPath => {
		const tagParts = tagPath.toLowerCase().split('/');
		const paperParts = paperPath.split('/');
		
		// Match based on how specific the tag selection is
		switch (tagParts.length) {
			case 1: // Topic level
				return paperParts[0] === tagParts[0];
			case 2: // Subtopic level
				return paperParts[0] === tagParts[0] && 
					   paperParts[1] === tagParts[1];
			case 3: // Tag level
				return paperPath === tagPath.toLowerCase();
			default:
				return false;
		}
	});
};

export const filterPapers = (papers, searchTerm, selectedTags) => {
	// If no filters are applied, return all papers
	if (!searchTerm && (!selectedTags || selectedTags.length === 0)) {
		return papers;
	}
	
	// Filter and score papers
	const scoredPapers = papers
		.map(paper => ({
			paper,
			score: calculatePaperScore(paper, searchTerm)
		}))
		.filter(({ paper, score }) => {
			const meetsSearchThreshold = !searchTerm || score > 0.2;
			const meetsTagFilters = matchesTags(paper, selectedTags);
			return meetsSearchThreshold && meetsTagFilters;
		})
		.sort((a, b) => b.score - a.score);
	
	return scoredPapers.map(({ paper }) => paper);
};
import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { ExternalLinkIcon, BookmarkIcon, TrashIcon, LightBulbIcon } from '@heroicons/react/outline';
import { Link, useLocation } from 'react-router-dom';
import { aiApi } from '../utils/apiUtils';
import { renderButton } from '../utils/uiUtils';
const PaperCard = ({ paper }) => {
	const [isFavorite, setIsFavorite] = useState(false);
	const [aiSummary, setAiSummary] = useState('');
	const [isLoadingSummary, setIsLoadingSummary] = useState(false);
	const { user, addFavoritePaper, removeFavoritePaper, addAiSummarizedPaper, eligibility } = useAuth();

	const isLoggedIn = user !== null;

	useEffect(() => {
		setIsFavorite(user?.favoritePapers?.includes(paper.id));
	}, [user, paper.id]);

	const handleAdd = async () => {
		if (!isLoggedIn || !eligibility.favoritePaperAllowed) return;
		console.log('Adding favorite paper ', paper.id);
		try {
			await addFavoritePaper(paper.id);
			setIsFavorite(true);
		} catch (error) {
			console.error('Error adding favorite paper:', error);
		}
	};

	const handleRemove = async () => {
		if (!isLoggedIn) return;
		try {
			await removeFavoritePaper(paper.id);
			setIsFavorite(false);
		} catch (error) {
			console.error('Error removing favorite paper:', error);
		}
	};

	const handleAISummary = async () => {
		if (!isLoggedIn || !eligibility.aiSummaryAllowed) return;

		setIsLoadingSummary(true);
		try {
			const { summary } = await aiApi.generateSummary(paper.id);
			await addAiSummarizedPaper(paper.id);
			setAiSummary(summary);

		} catch (error) {
			console.error('Error generating AI summary:', error);
		} finally {
			setIsLoadingSummary(false);
		}
	};

	const location = useLocation();

	return (
		<div className="bg-white rounded-lg shadow-md border border-gray-200 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg overflow-hidden">
			<div className="p-5 flex flex-col h-full">
				<div className="mb-3 flex flex-wrap gap-2">
					<span className="inline-block bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded">{paper.topic}</span>
					<span className="inline-block bg-gray-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">{paper.subtopic}</span>
					<span className="inline-block bg-green-100 text-green-800 text-xs font-semibold px-2.5 py-0.5 rounded">{paper.tag}</span>
				</div>
				<h2 className="mb-3 text-xl font-bold text-gray-900 line-clamp-2">{paper.title}</h2>
				<p className="mb-3 text-sm text-gray-600">{paper.first_author}</p>

				<p className="text-sm text-gray-500 mb-4 line-clamp-3">
					{paper.abstract}
				</p>

				{aiSummary && (
					<div className="mb-4 p-3 bg-yellow-50 rounded-md">
						<h3 className="text-sm font-semibold mb-1 text-yellow-800">AI Summary:</h3>
						<p className="text-sm text-yellow-900">{aiSummary}</p>
					</div>
				)}

				<div className="mt-auto flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3">
					<Link
						to={`/paper/${paper.id}`}
						state={{ from: location }}
						className="inline-flex items-center text-blue-600 hover:text-blue-800 transition duration-150 ease-in-out"
					>
						<span>Read More</span>
						<ExternalLinkIcon className="w-4 h-4 ml-1" />
					</Link>
					<div className="flex flex-wrap gap-2">
						{renderButton(
							handleAISummary,
							<LightBulbIcon className="w-4 h-4" />,
							isLoadingSummary ? 'Loading...' : 'AI Summary',
							'bg-purple-600',
							!isLoggedIn ? 'Login required' : eligibility.aiSummaryNotAllowedReason
						)}
						{isFavorite
							? renderButton(
								handleRemove,
								<TrashIcon className="w-4 h-4" />,
								'Remove',
								'bg-red-600',
								!isLoggedIn ? 'Login required' : ''
							)
							: renderButton(
								handleAdd,
								<BookmarkIcon className="w-4 h-4" />,
								'Save',
								'bg-green-600',
								!isLoggedIn ? 'Login required' : eligibility.favoritePaperNotAllowedReason
							)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaperCard;

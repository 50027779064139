import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <p className="mb-4 text-gray-600">
        Last updated: March 2024
      </p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
        <div className="space-y-4">
          <p>We collect information that you provide directly to us, including:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Email address when you sign up for an account</li>
            <li>Profile information when using third-party authentication (GitHub)</li>
            <li>Payment information when subscribing to premium features (processed securely through Stripe)</li>
            <li>Your paper preferences, including favorite papers and tags</li>
            <li>Usage data related to AI summary generation and paper interactions</li>
          </ul>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
        <div className="space-y-4">
          <p>We use the collected information to:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Provide and maintain our services</li>
            <li>Process your subscription payments</li>
            <li>Send you important notifications about your account</li>
            <li>Generate AI summaries of papers you request</li>
            <li>Track your usage of features within your subscription limits</li>
            <li>Send weekly paper digests based on your preferences</li>
          </ul>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Data Security</h2>
        <p className="mb-4">
          We implement appropriate security measures to protect your personal information. Your payment information is processed securely through Stripe and is never stored on our servers.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Email Communications</h2>
        <p className="mb-4">
          You will receive emails from us for account verification, subscription status updates, and weekly paper digests. You can manage your email preferences in your account settings.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at{' '}
          <a href="mailto:support@paperdigest.com" className="text-blue-600 hover:text-blue-800">
            support@paperdigest.com
          </a>
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'; // Add this import

function Footer() {
  return (
    <motion.footer 
      className="bg-gray-800 text-white py-6 sm:py-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}
    >
      <div className="container mx-auto px-4 text-center">
        <p className="text-sm sm:text-base">&copy; 2024 Paper Digest. All rights reserved.</p>
        <motion.div 
          className="mt-4 flex justify-center space-x-4"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.8, type: "spring" }}
        >
          {['Twitter', 'LinkedIn', 'GitHub'].map(platform => (
            <a key={platform} href={`#${platform.toLowerCase()}`} className="text-sm sm:text-base hover:text-blue-400 transition duration-150 ease-in-out">
              {platform}
            </a>
          ))}
        </motion.div>
        <motion.div 
          className="mt-4 flex justify-center space-x-4"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 1, type: "spring" }}
        >
          <Link to="/privacy-policy" className="text-sm sm:text-base hover:text-blue-400 transition duration-150 ease-in-out">
            Privacy Policy
          </Link>
          <Link to="/terms-of-service" className="text-sm sm:text-base hover:text-blue-400 transition duration-150 ease-in-out">
            Terms of Service
          </Link>
        </motion.div>
      </div>
    </motion.footer>
  );
}

export default Footer;
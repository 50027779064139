import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../context/AuthContext';
import { FaUserCircle, FaCog, FaSignOutAlt, FaBookmark, FaTags, FaRobot } from 'react-icons/fa';

function Header() {
  const { user, loading, signOut, eligibility } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (!loading) {
      setIsLoggedIn(user !== null);
    }
  }, [user, loading]);

  const handleSignOut = async () => {
    try {
      await signOut();
      setShowMenu(false);
      setIsLoggedIn(false);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const renderUsageMetrics = () => {
    if (!user) return null;

    const metrics = [
      { icon: FaBookmark, label: 'Favorite Papers', current: eligibility.favoritePaperUsage || 0, max: eligibility.favoritePaperQuota === Infinity ? '∞' : eligibility.favoritePaperQuota },
      { icon: FaTags, label: 'Favorite Tags', current: eligibility.favoriteTagUsage || 0, max: eligibility.favoriteTagQuota === Infinity ? '∞' : eligibility.favoriteTagQuota },
      { icon: FaRobot, label: 'AI Summaries', current: eligibility.aiSummaryUsage || 0, max: eligibility.aiSummaryQuota === Infinity ? '∞' : eligibility.aiSummaryQuota },
    ];

    return (
      <div className="px-4 py-2 border-t border-gray-200">
        <h3 className="text-sm font-semibold text-gray-700 mb-2">Usage Metrics</h3>
        {metrics.map((metric, index) => (
          <div key={index} className="flex items-center justify-between text-sm text-gray-600 mb-1">
            <div className="flex items-center">
              <metric.icon className="mr-2" />
              <span>{metric.label}:</span>
            </div>
            <span>
              {metric.current} / {metric.max}
            </span>
          </div>
        ))}
      </div>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <motion.header
      className="bg-transparent text-white py-4 sm:py-6 font-code"
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 100 }}
    >
      <div className="container mx-auto px-4 flex items-center justify-between">
        <Link to="/">
          <motion.h1
            className="text-3xl sm:text-4xl font-bold"
            whileHover={{ scale: 1.05 }}
          >
            Paper Digest
          </motion.h1>
        </Link>

        <nav className="flex items-center">
          <ul className="flex space-x-2 sm:space-x-4 mr-4">
            {[
              { name: 'Home', path: '/' },
              { name: 'Today', path: '/today' },
              // { name: 'Yesterday', path: '/yesterday' },
              { name: 'Archive', path: '/archive' },
              { name: 'Pricing', path: '/pricing' },
            ].map((item) => (
              <motion.li key={item.name}
                whileHover={{ scale: 1.1, rotate: 5 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link to={item.path} className="relative block">
                  <motion.span
                    className="relative z-10 block px-2 sm:px-4 py-2 text-sm sm:text-lg"
                    whileHover={{ color: "#FDE68A" }}
                  >
                    {item.name}
                  </motion.span>
                </Link>
              </motion.li>
            ))}
          </ul>

          <div className="relative">
            {isLoggedIn ? (
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowMenu(!showMenu)}
                className="cursor-pointer"
              >
                {(
                  <FaUserCircle className="w-10 h-10 text-white" />
                )}
              </motion.div>
            ) : (
              <Link to="/sign-in">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
                >
                  Sign Up / Sign In
                </motion.button>
              </Link>
            )}

            <AnimatePresence>
              {showMenu && isLoggedIn && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="absolute right-0 mt-2 w-64 bg-white rounded-md shadow-lg py-1 z-10"
                >
                  <Link to="/user" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    <FaUserCircle className="inline-block mr-2" />
                    Profile
                  </Link>
                  {renderUsageMetrics()}
                  <button
                    onClick={handleSignOut}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <FaSignOutAlt className="inline-block mr-2" />
                    Sign Out
                  </button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </nav>
      </div>
    </motion.header>
  );
}

export default Header;

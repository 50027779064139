import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaGoogle, FaGithub, FaTwitter, FaEnvelope } from 'react-icons/fa';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useAuth } from '../context/AuthContext';
import { emailAuthApi } from '../utils/apiUtils';
import { useNavigate } from 'react-router-dom';

function SignIn() {
  const { signIn } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (timerActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setTimerActive(false);
    }
    return () => clearInterval(timer);
  }, [timerActive, timeLeft]);

  const handleGoogleSignIn = () => {
    signIn('google').catch(error => {
      navigate('/error', {
        state: {
          error: {
            status: error.status || 500,
            message: error.message || 'Failed to sign in with Google',
            details: 'Please try again later or contact support if the problem persists.'
          }
        }
      });
    });
  };

  const handleGithubSignIn = () => {
    signIn('github').catch(error => {
      navigate('/error', {
        state: {
          error: {
            status: error.status || 500,
            message: error.message || 'Failed to sign in with GitHub',
            details: 'Please try again later or contact support if the problem persists.'
          }
        }
      });
    });
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    try {
      await emailAuthApi.requestEmailAuth(email);
      setShowCodeInput(true);
      setTimeLeft(60);
      setTimerActive(true);
    } catch (error) {
      setErrorMessage(error.message || 'Failed to send authentication code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    try {
      await emailAuthApi.verifyEmailAuth(email, code);
      window.location.href = '/user'; // Navigate to user page after successful verification
    } catch (error) {
      setErrorMessage(error.message || 'Invalid or expired code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    setLoading(true);
    setErrorMessage('');

    try {
      await emailAuthApi.requestEmailAuth(email);
      setTimeLeft(60);
      setTimerActive(true);
    } catch (error) {
      setErrorMessage(error.message || 'Failed to resend authentication code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 flex items-center justify-center min-h-screen">
        <motion.div
          className="w-full max-w-md"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.5,
            ease: "easeOut"
          }}
        >
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="p-8">
              <div className="text-center mb-8">
                <h2 className="text-3xl font-bold text-gray-900">Welcome Back</h2>
                <p className="mt-2 text-sm text-gray-600">
                  Sign in to continue to your account
                </p>
              </div>

              {errorMessage && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mb-6 rounded-lg bg-red-50 p-4 text-sm text-red-500"
                >
                  {errorMessage}
                </motion.div>
              )}

              <div className="space-y-4">
                {/* Active Google Sign In */}
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleGoogleSignIn}
                  className="w-full flex items-center justify-center px-4 py-3 rounded-lg border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                >
                  <FaGoogle className="w-5 h-5 mr-3 text-gray-600" />
                  <span className="text-sm font-medium">Continue with Google</span>
                </motion.button>

                {/* Active GitHub Sign In */}
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleGithubSignIn}
                  className="w-full flex items-center justify-center px-4 py-3 rounded-lg border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                >
                  <FaGithub className="w-5 h-5 mr-3 text-gray-600" />
                  <span className="text-sm font-medium">Continue with GitHub</span>
                </motion.button>

                {/* Email Authentication Form */}
                <form onSubmit={showCodeInput ? handleCodeSubmit : handleEmailSubmit} className="space-y-4">
                  <div>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      disabled={showCodeInput}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>

                  {showCodeInput && (
                    <div className="space-y-2">
                      <div className="relative">
                        <input
                          type="text"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          placeholder="Enter verification code"
                          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          disabled={timeLeft === 0}
                          required
                        />
                        {timeLeft > 0 && (
                          <span className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-gray-500">
                            {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
                          </span>
                        )}
                      </div>
                      {timeLeft === 0 && (
                        <motion.button
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                          onClick={handleResendCode}
                          disabled={loading}
                          className="w-full flex items-center justify-center px-4 py-2 rounded-lg bg-gray-100 text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                        >
                          <span className="text-sm font-medium">
                            {loading ? 'Sending...' : 'Resend Code'}
                          </span>
                        </motion.button>
                      )}
                    </div>
                  )}

                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    type="submit"
                    disabled={loading}
                    className="w-full flex items-center justify-center px-4 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                  >
                    <FaEnvelope className="w-5 h-5 mr-3" />
                    <span className="text-sm font-medium">
                      {loading ? 'Loading...' : showCodeInput ? 'Verify Code' : 'Sign in with Email'}
                    </span>
                  </motion.button>
                </form>

                {/* Coming Soon section */}
                <div className="relative my-6">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Coming Soon</span>
                  </div>
                </div>

                {/* Twitter - Coming Soon */}
                <button
                  disabled
                  className="w-full flex items-center justify-center px-4 py-3 rounded-lg border border-gray-200 bg-gray-50 text-gray-400 cursor-not-allowed"
                >
                  <FaTwitter className="w-5 h-5 mr-3" />
                  <span className="text-sm font-medium">Continue with Twitter</span>
                </button>
              </div>

              <p className="mt-6 text-center text-xs text-gray-600">
                By signing in, you agree to our{' '}
                <a href="/terms" className="text-blue-600 hover:text-blue-500">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a href="/privacy" className="text-blue-600 hover:text-blue-500">
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </GoogleOAuthProvider>
  );
}

export default SignIn;
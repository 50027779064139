import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Tooltip from './Tooltip';
import { subscriptionApi } from '../utils/apiUtils';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Pricing = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const isLoggedIn = user !== null;

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await subscriptionApi.getProducts();
      const products = response.products;
      setProducts(products);
    };
    fetchProducts();
  }, []);

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  const handleCheckout = async (product) => {
    if (!product) return;

    try {
      setLoading(true);
      const response = await subscriptionApi.createCheckoutSession(product);

      // Handle redirect response
      if (response.redirect) {
        navigate(response.redirect);
        return;
      } else if (response.sessionId) {
        // Handle normal checkout flow
        const { sessionId } = response;
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });

        if (error) {
          console.error('Stripe redirect error:', error);
        }
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderButton = (product) => {
    let buttonText = 'Select Plan';
    let onClick = () => handleProductSelect(product);

    let tooltipContent = '';
    let isDisabled = false;

    if (loading) {
      buttonText = 'Loading...';
      isDisabled = true;
    } else if (isLoggedIn && user.stripeSubscriptionTier === product.lookup_key) {
      buttonText = 'Current Plan';
      isDisabled = true;
    } else if (!isLoggedIn) {
      onClick = () => navigate('/sign-in');
      tooltipContent = 'Sign in to subscribe';
    } else if (selectedProduct?.id === product.id) {
      buttonText = 'Proceed to Checkout';
      onClick = () => handleCheckout(product);
    }

    return (
      <Tooltip content={tooltipContent}>
        <button
          className={`w-full py-3 px-4 rounded-full font-semibold transition-all duration-200 ${isDisabled
            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
            : selectedProduct?.id === product.id
              ? 'bg-green-500 text-white hover:bg-green-600 hover:shadow-md'
              : 'bg-blue-500 text-white hover:bg-blue-600 hover:shadow-md'
            }`}
          onClick={onClick}
          disabled={isDisabled}
        >
          {buttonText}
        </button>
      </Tooltip>
    );
  };

  return (
    <div className="max-w-6xl mx-auto mt-16 px-4">
      <h1 className="text-4xl font-bold mb-12 text-center text-gray-800">Choose Your Plan</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {products.map((product) => (
          <div
            key={product.id}
            className={`p-8 rounded-2xl transition-all duration-300 flex flex-col ${(isLoggedIn && user.subscriptionStatus === product.id)
              ? 'bg-white shadow-xl scale-105 border-2 border-green-500'
              : selectedProduct?.id === product.id
                ? 'bg-white shadow-xl scale-105 border-2 border-blue-500'
                : 'bg-gray-50 hover:shadow-lg hover:scale-102'
              }`}
          >
            <div className="flex-grow">
              <h2 className="text-2xl font-bold mb-4 text-gray-800">{product.name}</h2>
              <p className="text-3xl font-extrabold mb-6 text-blue-600">
                {product.price === 'Custom' ? 'Custom' : `$${product.price / 100}`}
                {product.price !== 'Custom' && <span className="text-lg font-normal text-gray-500">/month</span>}
              </p>
              <ul className="text-sm text-gray-600 mb-8 space-y-3">
                {product.features.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-auto">
              {renderButton(product)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
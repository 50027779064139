import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaSearch, FaTag, FaRobot, FaCalendarAlt, FaNewspaper, FaUsers, FaTags, FaFire } from 'react-icons/fa';
import PaperCollection from './PaperCollection';
import { statsApi } from '../utils/apiUtils';

function PaperDigest() {
  const [stats, setStats] = useState({
    totalPapers: 0,
    totalUsers: 0,
    totalTopics: {
      topics: 0,
      subtopics: 0,
      tags: 0
    }
  });
  const [hotPapers, setHotPapers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [statsData, hotPapersData] = await Promise.all([
          statsApi.getGeneralStats(),
          statsApi.getHotPapers()
        ]);
        setStats(statsData);
        console.log(hotPapersData)
        setHotPapers(hotPapersData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, []);

  // Add statistics items
  const statisticsItems = [
    {
      icon: <FaNewspaper className="text-2xl text-yellow-400" />,
      label: "Total Papers",
      value: stats.totalPapers.toLocaleString(),
    },
    {
      icon: <FaUsers className="text-2xl text-yellow-400" />,
      label: "Active Users",
      value: stats.totalUsers.toLocaleString(),
    },
    {
      icon: <FaTags className="text-2xl text-yellow-400" />,
      label: "Research Topics",
      value: stats.totalTopics.topics.toLocaleString() + " topics (" + stats.totalTopics.subtopics.toLocaleString() + " subtopics, " + stats.totalTopics.tags.toLocaleString() + " tags)",
    },

  ];

  const features = [
    {
      title: "Daily Research Updates",
      description: "Stay up-to-date with the latest research papers from arXiv, delivered to you daily.",
      icon: <FaCalendarAlt className="text-4xl mb-4 text-yellow-400" />
    },
    {
      title: "AI-Powered Summaries",
      description: "Get concise, AI-generated summaries of complex research papers to save time and boost understanding.",
      icon: <FaRobot className="text-4xl mb-4 text-yellow-400" />
    },
    {
      title: "Smart Search & Filtering",
      description: "Easily find relevant papers with our advanced search and tag filtering system.",
      icon: <FaSearch className="text-4xl mb-4 text-yellow-400" />
    },
    {
      title: "Customizable Tags",
      description: "Organize papers with customizable tags to suit your research interests and workflow.",
      icon: <FaTag className="text-4xl mb-4 text-yellow-400" />
    }
  ];

  // Get all PNG files from the /static/media directory
  const importAll = (r) => r.keys().map(r);
  const logos = importAll(require.context('/static/media', false, /\.png$/));

  // Duplicate the logos array for seamless infinite scroll
  const duplicatedLogos = [...logos, ...logos];

  return (
    <div className="container mx-auto px-4 py-12">


      <motion.h1
        className="text-5xl font-bold mb-8 text-center text-white"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Welcome to Paper Digest
      </motion.h1>
      <motion.p
        className="text-xl text-center text-gray-200 mb-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        Your AI-powered research companion for staying on top of the latest scientific discoveries
      </motion.p>

      <motion.div
        className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-12"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {statisticsItems.map((item, index) => (
          <motion.div
            key={index}
            className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-4 flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: index * 0.1 }}
          >
            <div className="flex items-center space-x-3">
              {item.icon}
              <div>
                <div className="text-gray-200 text-sm">{item.label}</div>
                <div className="text-white font-bold text-xl">{item.value}</div>
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>

      <motion.div
        className="mb-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        <div className="flex items-center justify-center mb-8">
          <FaFire className="text-3xl text-yellow-400 mr-3" />
          <h2 className="text-3xl font-bold text-center text-white">
            Hot Papers
          </h2>
        </div>
        <PaperCollection papers={hotPapers} />
      </motion.div>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg p-6 shadow-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            {feature.icon}
            <h3 className="text-2xl font-semibold mb-2 text-white">{feature.title}</h3>
            <p className="text-gray-200">{feature.description}</p>
          </motion.div>
        ))}
      </div> */}

      <motion.div
        className="mb-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        <h2 className="text-3xl font-bold text-center text-white mb-8">
          Trusted and Loved by
        </h2>
        <div className="relative overflow-hidden w-full h-20">
          <div className="flex absolute animate-scroll-slow">  {/* Changed from animate-scroll */}
            {duplicatedLogos.map((logo, index) => (
              <div
                key={index}
                className="flex items-center justify-center mx-8 min-w-[160px]"
              >
                <div className="bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg p-4">
                  <img
                    src={logo}
                    alt={`University logo ${index + 1}`}
                    className="h-10 object-contain mx-auto"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </motion.div>



      <motion.div
        className="text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        <Link to="/today">
          <motion.button
            className="bg-purple-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-purple-700 transition duration-300 ease-in-out"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Explore Today's Papers
          </motion.button>
        </Link>
      </motion.div>
    </div>
  );
}

export default PaperDigest;

import React, { useEffect } from 'react';
import { ArrowsExpandIcon } from '@heroicons/react/solid';
import { subscriptionApi } from '../utils/apiUtils';

const ChangeSubscription = () => {
	useEffect(() => {
		const redirectToPortal = async () => {
			try {
				const response = await subscriptionApi.createPortalSession();
				setTimeout(() => {
					if (response.url) {
						window.location.href = response.url;
					}
				}, 3000);
			} catch (error) {
				console.error('Error redirecting to portal:', error);
			}
		};

		redirectToPortal();
	}, []);

	return (
		<div className="flex flex-col items-center justify-center min-h-[60vh] space-y-6">
			<ArrowsExpandIcon className="w-12 h-12 animate-spin text-white" />
			<h2 className="text-xl text-white text-center font-semibold">
				Redirecting you to the customer portal...
			</h2>
		</div>
	);
};

export default ChangeSubscription; 